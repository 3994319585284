<template>
  <div class="cldialog-container">
    <el-dialog
      :title="isEdit ? '邀请详情' : '受邀详情'"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="22%"
    >
      <div class="container-detail">
        <div class="container-data">
          <div class="container-header" v-if="isEdit">
            成功邀请{{ content.receiveSuccessCount }}人，还剩下{{
              content.remainCount
            }}个名额
          </div>
          <div class="container-header" v-else>接受预约邀请</div>
          <div class="container-content" v-if="isEdit">
            <div
              v-for="(item, index) in content.receiverInfo"
              :key="index"
              style="display: flex; align-items: center; padding: 15px 0"
            >
              <div class="left">
                <img :src="item.receiverAvatar" />
              </div>
              <div class="right">
                <div class="name">{{ item.receiverCustomerName }}</div>
                <div class="time">{{ item.receiverDate }}</div>
              </div>
            </div>
          </div>
          <div class="container-content" v-else :class="isEdit ? '' : 'active'">
            <div class="left">
              <img :src="content.invitedAvatar" />
            </div>
            <div class="right">
              <div class="name">{{ content.invitedName }}</div>
              <div class="time">{{ content.time }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="nothing" v-else>
          <div v-if="isEdit">未进行邀请，无详情显示</div>
          <div v-else>无受邀信息，无详情显示</div>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import mixin from "@/mixins/dialogMixin";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    content: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    // getDataQuery(data) {
    //   console.log(data);
    // },
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeAddDialog() {
      //   await this.init();
      // this.$refs.bMap.clearData();
      this.$emit("closeAddDialog");
    },
    openDialog() {},
    /**
     * @function 初始化弹窗
     */
    init() {
      console.log("我初始化了");
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-container {
}
.container-detail {
  width: 100%;
  min-height: 200px;
  //   padding: 0 20px;
  overflow: hidden;
  .container-data {
    padding: 0 20px;
  }
  .container-header {
    margin-top: 20px;
  }
  .active {
    display: flex;
    margin-top: 30px;
    align-items: center;
  }
  .container-content {
    // display: flex;
    // margin-top: 30px;
    // align-items: center;
    .left {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      margin-left: 20px;
      .name {
        margin-bottom: 10px;
      }
    }
  }
}
.nothing {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  height: 150px;
  //   background: red;
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
