import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.searchCustomer = ''
      this.searchDayType = '1'
      this.searchReservationType = '1'
      this.statusType = ''
      this.sportsProjectCode = ''
      this.range = []
      this.range1 = []
    }
  }
}
export default searchFrom
