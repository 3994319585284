<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="80px">
            <el-row>
              <el-col :md="8" :sm="12">
                <el-form-item label="类型">
                  <el-select
                    placeholder="请选择"
                    v-model="form.searchReservationType"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in reservationTypeList"
                      :key="item.value"
                      :label="item.lable"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="下单时间">
                  <el-date-picker
                    style="width: 100%"
                    clearable
                    v-model="form.range"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="客户">
                  <el-input
                    placeholder="客户手机号/姓名"
                    v-model="form.searchCustomer"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="12">
                <el-form-item label="运动项目">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.sportsProjectCode"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in sportList"
                      :key="item.sportsProjectCode"
                      :label="item.sportsProjectName"
                      :value="item.sportsProjectCode"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="15">
                <el-form-item label="状态">
                  <el-select
                    placeholder="请选择"
                    clearable
                    v-model="form.statusType"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in typeList"
                      :key="item.value"
                      :label="item.lable"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8" :sm="18">
                <el-form-item label="预约日期">
                  <el-date-picker
                    style="width: 100%"
                    clearable
                    v-model="form.range1"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <div class="reservation-date">
                  <div
                    class="date-one"
                    v-for="(item, index) in dateList"
                    :key="item.value"
                    :class="currentIndex === index ? 'active' : ''"
                    @click="toSelect(index, item.value)"
                  >
                    {{ item.lable }}
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="4" class="text-right">
          <el-button @click="searchEvent" class="search-button">{{
            $t("search")
          }}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{
            $t("clean")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        :row-class-name="tableRowClassName"
      >
        <el-table-column type="index" align="center" label="序号" width="55">
        </el-table-column>
        <el-table-column type="index" align="center" label="类型" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.reservationTypeDesc }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="venueName"
          align="center"
          label="预订人"
          width="200"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.customerName }}</span>
            <span style="margin-left: 20px">{{ scope.row.customerPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sportsProjectName"
          align="center"
          label="预约日期"
          width="200"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.reservationDate }}</span>
            <span style="margin-left: 20px">{{ scope.row.weekDesc }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="200"
          prop="timeSlot"
          label="预约时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="sportsProjectName"
          min-width="120"
          label="运动项目"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="spaceName"
          min-width="180"
          label="场地"
        >
          <template slot-scope="scope">
            <div class="video-box">
              <div class="img" v-if="scope.row.liveBroadcast">
                <img src="@/assets/images/video.png" />
              </div>
              <div class="text">{{ scope.row.spaceName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          min-width="100"
          prop="price"
          show-overflow-tooltip
          label="金额（¥）"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="statusDesc"
          min-width="80"
          label="状态"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTime"
          width="200"
          label="下单时间"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderNo"
          width="150"
          label="订单号"
        >
          <template slot-scope="scope">
            <span class="order-text" @click="toPrint(scope.row)">{{
              scope.row.orderNo
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTime"
          width="200"
          label="详情"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="toDetail(scope.row, true)"
              v-if="scope.row.reservationType === '1'"
              >邀请详情</el-button
            >
            <el-button type="text" @click="toDetail(scope.row, false)" v-else
              >受邀详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column align="center" prop="income" width="150" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="toPrint(scope.row)">打印</el-button>
            <el-button type="text" @click="toRemark(scope.row)">备注</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-wrapper">
      <div class=""></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div>
    <!-- 小票弹窗 -->
    <DetailDialog
      ref="detailDialog"
      :show="detailDialog.visible"
      @closeDialog="changeDetailDialog"
      :isShow="detailDialog.isShow"
    ></DetailDialog>
    <addDialog
      :show="addDialog.visible"
      :Language="Language"
      ref="confirmDialog"
      :id="addDialog.remarkId"
      @sureEvent="sureEvent"
      @closeDialog="closeAddDialog"
    ></addDialog>
    <detailDialog
      :show="newDialog.visible"
      :isEdit="newDialog.isEdit"
      :content="newDialog.content"
      @closeDialog="closeNewDialog"
    >
    </detailDialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import apis from "@/apis";
import mixin from "@/mixins/index";
import UserSearchClass from "./userSearch";
import DetailDialog from "../../venueOperation/order/compoments/detailDialog";
import addDialog from "./dialog/addDialog";
import detailDialog from "./dialog/detailDialog";

export default {
  mixins: [mixin],
  components: { DetailDialog, addDialog, detailDialog },
  data() {
    return {
      form: new UserSearchClass("form"), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        remarkId: "",
      },
      businessTypes: [],
      typeList: [
        {
          lable: "未使用",
          value: "1",
        },
        {
          lable: "已使用",
          value: "2",
        },
        {
          lable: "已取消",
          value: "3",
        },
        {
          lable: "已过期",
          value: "4",
        },
      ],
      sportList: [],
      reservationTypeList: [
        {
          lable: "自购",
          value: "1",
        },
        {
          lable: "受邀",
          value: "2",
        },
      ],
      dateList: [
        {
          lable: "今日",
          value: "1",
        },
        {
          lable: "明日",
          value: "2",
        },
        {
          lable: "本周",
          value: "3",
        },
        {
          lable: "下周",
          value: "4",
        },
      ],
      currentIndex: 0,
      detailDialog: {
        visible: false,
        isShow: true,
      },
      newDialog: {
        visible: false,
        isEdit: true,
        content: {},
      },
    };
  },
  computed: {
    ...mapState(["Language", "TOKEN", "venueId"]),
  },
  filters: {},
  async created() {
    this.getSportList();
  },
  activated() {},
  methods: {
    closeAddDialog() {
      this.addDialog.visible = false;
      this.searchData();
    },
    closeNewDialog() {
      this.newDialog.visible = false;
    },
    sureEvent(remark) {
      console.log(remark);
    },
    /**
     * @function 关闭小票预览弹窗
     */
    changeDetailDialog() {
      console.log(1111111);
      this.detailDialog.visible = false;
    },
    toPrint(row) {
      console.log(row);
      if (row.reservationType === "2") {
        this.detailDialog.isShow = false;
        this.$nextTick(() => {
          this.$refs.detailDialog.getDataQuery(row.sharingResultId);
        });
      } else {
        this.detailDialog.isShow = true;
        this.$nextTick(() => {
          this.$refs.detailDialog.getDataQuery(row.orderId);
        });
      }
      this.detailDialog.visible = true;
    },
    toDetail(row, type) {
      this.newDialog.isEdit = type;
      if (type) {
        this.$http
          .get(`${apis.getInvitedDetail}?orderDetailId=${row.orderDetailId}`)
          .then((res) => {
            if (res.data.code === 0) {
              this.newDialog.content = res.data.data;
              this.newDialog.visible = true;
            }
          });
      } else {
        this.$http
          .get(`${apis.getBeInvitedDetail}?orderId=${row.orderId}`)
          .then((res) => {
            if (res.data.code === 0) {
              console.log(res.data);
              console.log("aaaaaaa");
              this.newDialog.content = res.data.data;
              this.newDialog.visible = true;
            }
          });
      }
    },
    toRemark(row) {
      this.$refs["confirmDialog"].getDataQuery(row);
      this.addDialog.remarkId = row.reservationType === "1" ? row.orderId : row.sharingResultId;
      this.addDialog.visible = true;
    },
    toSelect(index, value) {
      this.form.pageNum = 1;
      this.currentIndex = index;
      this.form.searchDayType = value;
      this.searchData();
    },
    getSportList() {
      this.$http
        .get(`${apis.getSportsNewProject}?id=${this.venueId}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.sportList = res.data.rows;
          }
        });
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      const formData = {
        ...this.form,
      };
      if (formData.range) {
        if (formData.range.length > 0) {
          formData.orderStartTime = this.form.range[0];
          formData.orderEndTime = this.form.range[1];
        }
      } else {
        formData.orderStartTime = "";
        formData.orderEndTime = "";
      }
      if (formData.range1) {
        if (formData.range1.length > 0) {
          formData.reservationStartTime = this.form.range1[0];
          formData.reservationEndTime = this.form.range1[1];
        }
      } else {
        formData.reservationStartTime = "";
        formData.reservationEndTime = "";
      }
      this.$http
        .post(apis.reservationRecord, {
          ...formData,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
          }
        });
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new UserSearchClass("form");
      this.currentIndex = 0;
      this.form.searchDayType = "1";
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
}
.table-container {
  margin-top: 0;
}
.reservation-date {
  margin-left: 17px;
  display: flex;
  .date-one {
    cursor: pointer;
    width: 57px;
    height: 28px;
    border: 1px solid rgba(217, 217, 217);
    text-align: center;
    line-height: 28px;
    font-size: 14px;
    color: #666666;
    margin-left: 10px;
  }
  .active {
    color: #108ee9;
    border: 1px solid #108ee9;
  }
  .date-one:nth-child(1) {
    margin-left: 0;
  }
}
.order-text {
  cursor: pointer;
  color: #108ee9;
}
.video-box {
  display: flex;
  align-items: center;
  justify-content: center;
  .img {
    width: 25px;
    height: 25px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text {
    padding: 0 10px;
  }
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
