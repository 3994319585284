/*
 * @Author: your name
 * @Date: 2020-09-08 11:50:46
 * @LastEditTime: 2020-10-15 15:33:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\venueMana\physique\dialog\addDialogClass.js
 */
/* eslint-disable */
class searchFrom {
    constructor(type) {
        if (type === 'form') {
            this.remark = ''
        } else if (type === 'rule') {
            this.remark = [
                {
                    required: true,
                    message: '请输入备注',
                    trigger: 'blur',
                },
            ]
        }
    }
}
export default searchFrom
