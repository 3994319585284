<template>
  <div class="cldialog-container">
    <el-dialog
      title="添加备注"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="25%"
    >
      <el-form ref="form" :model="form" label-width="0px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item prop="remark">
              <el-input
                clearable
                type="textarea"
                :rows="10"
                :maxlength="100"
                placeholder="请输入该订单的备注信息（商家备注）"
                v-model="form.remark"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{
          $t("sure")
        }}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";
import mixin from "@/mixins/dialogMixin";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    id: {
      required: true,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      reservationType: "",
    };
  },
  methods: {
    getDataQuery(data) {
      this.reservationType = data.reservationType;
      console.log(data);
      this.form.remark = data.remark;
    },
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.reservationType === "1") {
            this.form.id = this.id;
          } else {
            this.form.sharingResultId = this.id;
          }
          this.$http
            .post(
              this.reservationType === "1"
                ? apis.updateRemark
                : apis.updateSharingResultRemark,
              this.form,
            )
            .then((res) => {
              if (res.data.code === 0) {
                this.closeDialog();
              }
            });
        }
      });
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      console.log("我初始化了");
    },
    openDialog() {
      // this.$http.post(apis.orderDetails, { id: this.id }).then((res) => {
      //   if (res.data.code === 0) {
      //     this.form.remark = res.data.rows.businessRemark;
      //   }
      // });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-container {
}
.content {
  height: 150px;
  //   background: red;
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
